jQuery(document).ready(function ($) {
  
  

  
  
  
  if(localStorage.getItem("iknowyou")) {
      $('.overlay').fadeOut();
    } else {
      
      localStorage.setItem("iknowyou", "true");
    }
  
  
  var hours = 1; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
    }
}
  
  
  
  
   });





  jQuery(document).ready(function ($) {
  
    $('.gallery').masonry({
    // options
    itemSelector: '.gallery-item',
    percentPosition: true
  });
   
$('.wpforms-recaptcha-container').hide();
$('#wpforms-82-field_2').on('input', function() {
        var textareaValue = $(this).val().trim(); // Получаем значение textarea и удаляем лишние пробелы
        if (textareaValue.length > 0) {
            $('.wpforms-recaptcha-container').show(); // Показываем блок .wpforms-recaptcha-container
        } else {
            $('.wpforms-recaptcha-container').hide(); // Скрываем блок .wpforms-recaptcha-container
        }
    });

    
    
    $('.wp-block-latest-posts__list a').hover(function() {
      $(this).parent().find('img').toggleClass('hover');
    
   
  }); 
    
    
    $('.mobile-menu').click(function() {
      
      $('.left-sidebare').fadeToggle(100);
    
   
  }); 
    
   
    
    
     
   
  

      
      
      
   
  
  function loader() {
      
      $("body").addClass('anim');
      
    }
    setTimeout(loader, 300);
  
  //$(".entry-content")

  
//  $(document).ready(function() {
//  if ($("body").hasClass("thumbnails-on")) {
//    $('html, body, *').mousewheel(function(e, delta) {
//    this.scrollLeft -= (delta);
//    e.preventDefault();
//  });
//}
//
//});
  
  
  

      
  
  $('.overlay').click(function() {
   
    $(this).fadeOut();
  });
  
    
    // Получите изображение и элементы модального окна
var images = $(".gallery img");
var modal = $("#modal");
var modalImage = $("#modal-image");
var modalClose = $("#close-modal");
var caption = $("#caption");
var currentIndex = 0;

// Предварительная загрузка всех изображений
var imgObjects = [];
images.each(function() {
    var imgObj = new Image();
    imgObj.src = $(this).attr("src");
    imgObjects.push(imgObj);
});

// Когда пользователь нажимает на изображение, откройте модальное окно и отобразите изображение
images.click(function() {
    currentIndex = images.index(this);
    showImage($(this));
});

// Когда пользователь нажимает на изображение модального окна, закройте модальное окно
modalImage.click(function() {
    modal.hide();
    caption.empty();
});

modalClose.click(function() {
    modal.hide();
    caption.empty();
});

// Кнопки "Следующий" и "Предыдущий"
$("#next").click(function() {
    currentIndex = (currentIndex + 1) % images.length;
     caption.empty();
    showImage($(images[currentIndex]));
 
});

$("#prev").click(function() {
    currentIndex = (currentIndex - 1 + images.length) % images.length;
  caption.empty();
    showImage($(images[currentIndex]));
  
});

// Функция для отображения изображения в модальном окне
function showImage(img) {
    modal.show();
    modalImage.attr("src", img.attr("src"));
    modalImage.attr("alt", img.attr("alt"));
    
    var figcaptionText = img.closest("figure").find("figcaption.wp-caption-text").html();
    caption.html(figcaptionText);
    var altText = img.attr('alt'); 
    var boldParagraph = '<p class="m-0"><strong>' + altText + '</strong></p>';
    caption.prepend(boldParagraph); 
   
}

// Обработчики событий клавиатуры
$(document).keydown(function(e) {
    switch(e.which) {
        case 37: // left arrow key
            $("#prev").click();
            break;
        case 39: // right arrow key
            $("#next").click();
            break;
        default: return;
    }
    e.preventDefault();
});
  // Подключите Hammer.js
var hammer = new Hammer(document.body);

// Обработчики событий свайпа
hammer.on("swipeleft", function() {
  $("#next").click();
});

hammer.on("swiperight", function() {
  $("#prev").click();
});
  
  
    
    
  
  
  
   });
